import { BLOCK_MAP } from '../../../pages/[...slug]'
import styles from './ApiPageContent.module.scss'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as ReactDOMClient from 'react-dom/client'
import cx from 'classnames'
import ScrollTopButton from '../../ScrollTopButton'
import cleanString from '../../../lib/cleanString'
import { Disclosure } from '@headlessui/react'
import { Check, ChevronDown, ChevronUp, X } from 'react-feather'

const ENABLE_SEARCH_FUNCTION = false

export const ApiPageContent = ({ content }) => {
  const contentRef = useRef(null)

  const [firstVisibleHeading, _setFirstVisibleHeading] = useState(null)
  const firstVisibleHeadingRef = useRef(firstVisibleHeading)
  const setFirstVisibleHeading = data => {
    firstVisibleHeadingRef.current = data
    _setFirstVisibleHeading(data)
  }

  const [lastVisibleHeading, _setLastVisibleHeading] = useState(null)
  const lastVisibleHeadingRef = useRef(lastVisibleHeading)
  const setLastVisibleHeading = data => {
    lastVisibleHeadingRef.current = data
    _setLastVisibleHeading(data)
  }

  const [navItems, setNavItems] = useState([])

  // Finds all headings in the content area and restructures them into a tree
  useEffect(() => {
    if (!contentRef?.current) return

    const headings = contentRef.current.querySelectorAll('h2, h3, h4, h5, h6')

    const root = { element: { tagName: 'div', id: 'nav-root' }, children: [] }
    let currentParent = root

    for (let i = 0; i < headings.length; i++) {
      const heading = headings[i]
      const level = parseInt(heading.tagName.charAt(1))

      if (level === 2) {
        currentParent = { element: heading, children: [] }
        root.children.push(currentParent)
      } else {
        while (
          currentParent &&
          level <= parseInt(currentParent.element.tagName.charAt(1))
        ) {
          currentParent = currentParent.parent
        }
        const newParent = { element: heading, children: [] }
        currentParent.children.push(newParent)
        newParent.parent = currentParent
        currentParent = newParent
      }
    }

    setNavItems(root)
  }, [contentRef])

  // Convert 'y' and 'n' inside table cells to data attributes,
  // so they can be styled as icons in css
  useEffect(() => {
    if (!contentRef?.current) return
    ;[...contentRef.current.querySelectorAll('table td p')]
      .filter(el => el.innerText === 'y' || el.innerText === 'n')
      .forEach(el => {
        if (!el.dataset?.boolIcon) {
          el.dataset.boolIcon = el.innerText === 'y'

          const iconRoot = ReactDOMClient.createRoot(el)
          iconRoot.render(el.innerText === 'y' ? <Check /> : <X />)
        }
      })
  }, [contentRef])

  function renderTreeAsList(node, level = 0) {
    const className = `level-${level}`

    return level === 0 ? (
      node?.children?.map(child => renderTreeAsList(child, level + 1))
    ) : level === 1 ? (
      <Disclosure
        as={'div'}
        key={node.element.textContent.replace(' ', '-')}
        className={cx(className, styles.disclosure)}
        defaultOpen={true}
      >
        {({ open }) => (
          <>
            <Disclosure.Button className={styles.disclosureButton}>
              <div>{node.element.textContent}</div>
              {open ? <ChevronUp /> : <ChevronDown />}
            </Disclosure.Button>
            <Disclosure.Panel>
              <ul className={styles.navItems}>
                {node.children.map(child => (
                  <li
                    key={child.element.textContent.replace(' ', '-')}
                    className={styles[className]}
                    data-level={level}
                  >
                    {renderTreeAsList(child, level + 1)}
                  </li>
                ))}
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    ) : (
      <ul className={styles.navItems}>
        {node?.element?.textContent && (
          <li
            data-level={level}
            className={cx({
              [styles.active]: isActiveHeading(node),
            })}
          >
            <a onClick={event => handleClick(event, node.element)}>
              {node.element.textContent}
            </a>
          </li>
        )}

        {node?.children?.map(child => (
          <li
            key={child.element.textContent.replace(' ', '-')}
            className={styles[className]}
            data-level={level}
          >
            {renderTreeAsList(child, level + 1)}
          </li>
        ))}
      </ul>
    )
  }

  function handleClick(event, targetElement) {
    event.preventDefault()
    window.scrollTo({
      top: contentRef.current.offsetTop + targetElement.offsetTop,
      behavior: 'smooth',
    })
  }

  const isActiveHeading = heading => {
    return (
      firstVisibleHeadingRef?.current?.innerText ===
        heading?.element?.innerText ||
      lastVisibleHeadingRef?.current?.innerText === heading?.element?.innerText
    )
  }

  const checkActiveTarget = useCallback(() => {
    if (!contentRef?.current) return

    const headings = contentRef.current.querySelectorAll('h2, h3, h4, h5, h6')

    const visibleHeadings = [...headings].filter(
      heading =>
        heading.getBoundingClientRect().top > 0 &&
        heading.getBoundingClientRect().top < window.innerHeight
    )

    if (visibleHeadings && visibleHeadings.length) {
      if (firstVisibleHeadingRef.current !== visibleHeadings[0]) {
        setFirstVisibleHeading(visibleHeadings[0])
        setLastVisibleHeading(firstVisibleHeadingRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', checkActiveTarget)
      window.addEventListener('scroll', checkActiveTarget)
    }
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  useEffect(() => checkActiveTarget(), [contentRef])

  function findStringInDOM(searchString) {
    if (!ENABLE_SEARCH_FUNCTION) return null

    // Traverse the DOM to find the string
    const allNodes = contentRef.current.getElementsByTagName('*')
    let lastHeading = null

    let foundNodes = []
    for (let i = 0; i < allNodes.length; i++) {
      const node = allNodes[i]

      if (/^h[2-6]$/i.test(node.tagName)) {
        lastHeading = node
      }

      if (
        ['p', 'th', 'td'].includes(node.tagName.toLowerCase()) &&
        node.textContent.toLowerCase().indexOf(searchString.toLowerCase()) !==
          -1
      ) {
        foundNodes.push({ node, lastHeading })
      }
    }

    const results = []

    for (const { node, lastHeading } of foundNodes) {
      // Get the surrounding text
      const text = node.textContent
      let retText = searchString
      if (text) {
        const sorroundLen = 20
        const startIndex =
          text?.toLowerCase()?.indexOf(searchString.toLowerCase()) ?? 0
        const endIndex = startIndex + searchString.toLowerCase().length
        const beforeText = text.substring(
          Math.max(startIndex - sorroundLen, 0),
          startIndex
        )
        const afterText = text.substring(endIndex, endIndex + sorroundLen)

        retText = `${beforeText}<span className='highlight-search'>${searchString}</span>${afterText}`
      }

      results.push({
        node,
        text: retText,
        heading: lastHeading,
      })
    }

    return results
  }

  useEffect(() => {
    document.querySelector('body').style.width = '100vw'
    document.querySelector('body').style.overflowX = 'hidden'
  }, [])

  function changeOverflow() {
    if (window.innerWidth > 1023) {
      document.querySelector('body').style.overflowY = 'hidden'
    }
  }

  function restoreOverflow() {
    if (window.innerWidth > 1023) {
      document.querySelector('body').style.overflowY = 'auto'
    }
  }

  return (
    <>
      <div className={styles.sectionWrapper}>
        {content.headline && (
          <h2
            className={styles.headline}
            dangerouslySetInnerHTML={{ __html: cleanString(content.headline) }}
          />
        )}

        <div className={styles.sectionInner}>
          <div
            className={styles.indexWrapper}
            onMouseOver={changeOverflow}
            onMouseOut={restoreOverflow}
          >
            {ENABLE_SEARCH_FUNCTION ? (
              <div>
                <input
                  type="text"
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      const searchResult = findStringInDOM(e.target.value)
                      console.log(searchResult)
                    }
                  }}
                />
              </div>
            ) : null}
            {renderTreeAsList(navItems)}
          </div>

          <div className={styles.contentWrapper} ref={contentRef}>
            {content?.pagecontent &&
              content.pagecontent.map((block, i) => {
                const Block = BLOCK_MAP[block.type] || null
                if (Block) {
                  return (
                    <Block
                      key={i}
                      className={styles[block.type]}
                      content={block}
                      isSection={false}
                    />
                  )
                }
              })}
          </div>

          <ScrollTopButton />
        </div>
      </div>
    </>
  )
}

export default ApiPageContent

import styles from './Headline.module.scss'
import cx from 'classnames'
import cleanString from '../../../lib/cleanString'
import Section from '../../Section'

export const HeadlineBlock = ({ content }) => {
  let {
    alignment,
    headline,
    teaser: teaser,
    headlineblock_color: { value: color },
    headlineblock_gradient: gradient,
    headlineblock_gradient_color: { value: gradientColor },
    headlineblock_gradient_position: { value: gradientPosition },
  } = content

  if (!alignment.value) {
    alignment = {
      value: 'center',
    }
  }

  return (
    <Section
      color={color}
      gradient={gradient}
      gradientColor={gradientColor}
      gradientPosition={gradientPosition}
    >
      <div className={styles.containerInner}>
        <div
          className={cx(styles.textContent, {
            [styles.alignLeft]: alignment.value === 'left',
            [styles.alignRight]: alignment.value === 'right',
            [styles.alignCenter]: alignment.value === 'center',
          })}
        >
          <h2
            className={cx(styles.headline, {
              'text-white': color === 'blue' || color === 'dark-blue',
              'text-purple': color === 'yellow' || color === 'white',
            })}
            dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
          />
          <div
            className={cx(styles.teaser, {
              'text-white': color === 'blue',
              'text-purple': color === 'yellow' || color === 'white',
            })}
            dangerouslySetInnerHTML={{ __html: teaser }}
          />
        </div>
      </div>
    </Section>
  )
}

export default HeadlineBlock

import styles from './CaseStudiesSlide.module.scss'
import cx from 'classnames'
import { SwiperSlide } from 'swiper/react'
import { Star } from 'react-feather'
import ImageComponent from '../../../Image'

export const CaseStudiesSlide = ({ content, className }) => {
  const {
    icon: icon,
    info: employeeDescription,
    person: employeeName,
    quote: quote,
    rating: rating,
  } = content

  return (
    <SwiperSlide className={cx(styles.slide, className)}>
      <div>
        <div className={styles.rating}>
          {rating > 0 &&
            Array(rating)
              .fill(0)
              .map((_, index) => (
                <Star key={index} className={cx(styles.star, styles.fill)} />
              ))}
          {rating < 5 &&
            Array(5 - rating)
              .fill(0)
              .map((_, index) => (
                <Star key={index} className={cx(styles.star)} />
              ))}
        </div>

        <div
          className={styles.quote}
          dangerouslySetInnerHTML={{ __html: quote.trim() }}
        />
      </div>

      <div className={styles.employee}>
        {icon && (
          <div className={styles.icon}>
            <ImageComponent
              src={icon.permalink}
              layout="fixed"
              width={60}
              height={60 / icon?.ratio ?? 1}
              quality={80}
              objectFit="contain"
              alt=""
            />
          </div>
        )}
        <div>
          <div className={styles.name}>{employeeName}</div>
          <div className={styles.description}>{employeeDescription}</div>
        </div>
      </div>
    </SwiperSlide>
  )
}

CaseStudiesSlide.displayName = 'SwiperSlide'

export default CaseStudiesSlide

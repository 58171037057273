import styles from './Facts.module.scss'
import ImageComponent from '../../Image'
import cleanString from '../../../lib/cleanString'

const Fact = ({ item }) => {
  const { icon, headline, teaser } = item

  return (
    <div className={styles.factWrapper}>
      {icon && (
        <div className={styles.factIconWrapper}>
          <ImageComponent
            src={icon.permalink}
            alt={icon.alt}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      )}
      <div className={styles.factTextWrapper}>
        {headline && (
          <h5 dangerouslySetInnerHTML={{ __html: cleanString(headline) }} />
        )}
        {teaser && <div dangerouslySetInnerHTML={{ __html: teaser }} />}
      </div>
    </div>
  )
}

export const Facts = ({ content }) => {
  const { headline, items } = content

  return (
    <section className={styles.sectionWrapper}>
      {headline && (
        <h2
          className="text-center mb-20"
          dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
        />
      )}
      <div className={styles.factsWrapper}>
        {items && items.map((item, i) => <Fact key={i} item={item} />)}
      </div>
    </section>
  )
}

export default Facts

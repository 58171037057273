import styles from './HomeHero.module.scss'
import parse from 'html-react-parser'
import cx from 'classnames'
import CustomLink from '../CustomLink'
import cleanString from '../../../lib/cleanString'
import useTranslation from 'next-translate/useTranslation'

export const HomeHero = ({ content, isSection = true }) => {
  const { lang } = useTranslation()

  const {
    hero_color: color,
    subtitle: subtitle,
    teaser: teaser,
    title: title,
    link: customLink,
    linktext: customLinkText,
    linktype: customLinkType,
    newtab: customLinkNewTab,
    color: customLinkColor,
  } = content

  return (
    <>
      <section
        className={cx(styles.sectionWrapper, {
          'bg-white': color.value === 'white',
          'bg-yellow': color.value === 'yellow',
        })}
      >
        <div
          className={cx(styles.sectionInner, {
            [styles.noSection]: !isSection,
            [styles.section]: isSection,
          })}
        >
          <div
            className={cx('w-full md:w-1/2', styles.heroLeft, {
              [styles.noSection]: !isSection,
              [styles.section]: isSection,
            })}
          >
            {subtitle && <div className={styles.teaser}>{parse(subtitle)}</div>}
            {title && (
              <h1
                className={cx(styles.headline, {
                  [styles.noSection]: !isSection,
                  [styles.section]: isSection,
                  'text-white': color.value === 'yellow',
                })}
                dangerouslySetInnerHTML={{ __html: cleanString(title) }}
              />
            )}
            {teaser && <div className={styles.teaser}>{parse(teaser)}</div>}
            {customLink && (
              <CustomLink
                linkType={customLinkType}
                link={customLink}
                text={customLinkText}
                newTab={customLinkNewTab}
                color={customLinkColor.value}
                className="mt-8"
              >
                {customLinkText}
              </CustomLink>
            )}
          </div>
          <div className={styles.heroRight}>
            <div className={styles.videoBackground}>
              {lang && (
                <video
                  src={`/static/videos/hero-main-${lang}.mp4`}
                  muted={true}
                  autoPlay={true}
                  controls={false}
                  loop={true}
                />
              )}
            </div>
            <div className={styles.videoForeground}>
              <video
                src={`/static/videos/hero-terminal-${lang}.mp4`}
                muted={true}
                autoPlay={true}
                controls={false}
                loop={true}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HomeHero

import React, { useCallback, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Form, Formik } from 'formik'
import { Button } from '../../../Button'
import useValidationSchema from './validationSchema'
import { useFormModel } from './formModel'
import { useInitialValues } from './formInitialValues'
import { Input, Textarea } from '../../../Forms'
import Select from '../../../Forms/Select'
import axios from 'axios'

export const ContactForm = ({ formikRef, setMessageSent, privacyContent }) => {
  const { t } = useTranslation('contact')

  const {
    formId,
    formField: { firstName, lastName, email, subject, message, phone },
  } = useFormModel()
  const validationSchema = useValidationSchema()
  const formInitialValues = useInitialValues()

  const [contactFailed, setContactFailed] = useState(false)

  const subjectOptions = ['general', 'sales', 'cooperation'].map(subject => ({
    value: subject,
    label: t(`form.subject.label_${subject}`),
  }))

  const _handleSubmit = useCallback(
    async (values, { setSubmitting, resetForm }) => {
      setContactFailed(false)
      setSubmitting(true)

      if (window?.dataLayer) {
        window.dataLayer.push({
          event: 'form_submit',
        })
      }

      try {
        const response = await axios.post('/api/contact', { values })

        if (response.status !== 200 || !response.data.success) {
          setContactFailed(true)
        } else {
          resetForm()
          setMessageSent(true)
        }
      } catch (e) {
        setContactFailed(true)
      }

      setSubmitting(false)
    },
    [setMessageSent]
  )

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={_handleSubmit}
      >
        {({ isSubmitting, isValid }) => {
          return (
            <Form id={formId}>
              {contactFailed && (
                <div className="p-4 mb-6 rounded-md text-red-500 bg-red-200 border border-red-500">
                  <p>
                    {t('errorPre')}{' '}
                    <a
                      href="mailto:info@paymenttools.com"
                      className="text-purple-light"
                    >
                      info@paymenttools.com
                    </a>
                    .
                  </p>
                </div>
              )}

              <div>
                <div className="mb-6">
                  <div
                    className={'w-full flex flex-wrap md:flex-nowrap gap-x-4'}
                  >
                    <div className={'w-full md:w-1/2'}>
                      {firstName.meta}
                      <Input
                        type="text"
                        name={firstName.name}
                        label={`${firstName.label}`}
                        required={firstName.required}
                      />
                    </div>
                    <div className={'w-full md:w-1/2'}>
                      <Input
                        type="text"
                        name={lastName.name}
                        label={`${lastName.label}`}
                        required={lastName.required}
                      />
                    </div>
                  </div>
                  <div>
                    <Input
                      type="text"
                      name={email.name}
                      label={`${email.label}`}
                      required={email.required}
                    />
                  </div>
                  <div>
                    <Input
                      type="text"
                      name={phone.name}
                      label={`${phone.label}`}
                      required={phone.required}
                    />
                  </div>
                  <div>
                    <Select
                      label={t('form.subject.label_pre')}
                      name={subject.name}
                      options={subjectOptions}
                      placeholder={t('form.subject.label_pre')}
                      required={subject.required}
                    />
                  </div>
                  <div>
                    <Textarea
                      name={message.name}
                      label={`${message.label}`}
                      rows="3"
                      required={message.required}
                      placeholder={''}
                    />
                  </div>
                </div>

                <div className={'flex items-start justify-between'}>
                  <Button
                    small
                    isLoading={isSubmitting}
                    disabled={!isValid}
                    type="submit"
                  >
                    {t('form.submit.label')}
                  </Button>
                  <span className={'text-12-18'}>
                    {t('form.mandatoryField')}
                  </span>
                </div>

                <div
                  className="text-gray text-14-24 mt-4"
                  dangerouslySetInnerHTML={{ __html: privacyContent }}
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ContactForm

import styles from './PaymentLogoBar.module.scss'
import cx from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { SiteInformationContext } from '../../../context/site-information-context'
import ImageComponent from '../../Image'
import Section from '../../Section'

export const PaymentLogoBar = ({ content, className }) => {
  const {
    color: color = 'white',
    gradient,
    gradient_color: gradientColor,
    gradient_position: gradientPosition,
    paymententries: itemIds = [],
  } = content

  const siteInformationContext = useContext(SiteInformationContext)
  const [siteInformation, setSiteInformation] = useState({})
  useEffect(() => {
    if (siteInformationContext && siteInformationContext) {
      setSiteInformation(siteInformationContext)
    }
  }, [siteInformationContext])

  const [displayItems, setDisplayItems] = useState([])
  useEffect(() => {
    setDisplayItems(
      siteInformation?.payment_methods?.data?.filter(
        item => itemIds.find(id => id === item.id) && !!item.iconsw
      ) || []
    )
  }, [content, itemIds, siteInformation?.payment_methods?.data])

  return (
    <Section
      className={cx(styles.sectionWrapper, className)}
      color={color?.value}
      gradient={gradient}
      gradientColor={gradientColor?.value}
      gradientPosition={gradientPosition?.value}
    >
      <div className={styles.sectionInner}>
        {displayItems &&
          displayItems.map(item => (
            <div key={item.iconsw.permalink} className={styles.logo}>
              <ImageComponent
                src={item.iconsw.permalink}
                alt=""
                width={item.iconsw.width}
                height={item.iconsw.height}
              />
            </div>
          ))}
      </div>
    </Section>
  )
}

export default PaymentLogoBar

import styles from './Benefits.module.scss'
import { useEffect, useState } from 'react'
import twConfig from '../../../tailwind.config'
import Nyan from '../../../public/images/nyan.gif'
import ImageComponent from '../../Image'
import cleanString from '../../../lib/cleanString'

const Benefit = ({ benefit: { icon, headline, teaser }, color }) => {
  return (
    <div className={styles.benefitWrapper} style={{ backgroundColor: color }}>
      {icon && (
        <div className={styles.benefitIconWrapper}>
          <div className={styles.deco} />
          <ImageComponent
            src={icon.permalink}
            alt={icon.alt}
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      )}
      <div className={styles.benefitTextWrapper}>
        {headline && (
          <h3
            className="text-16-25 mb-0"
            dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
          />
        )}
        {teaser && (
          <div
            className="text-16-25"
            dangerouslySetInnerHTML={{ __html: teaser }}
          />
        )}
      </div>
    </div>
  )
}

const BenefitsBlock = ({ content: { benefits } }) => {
  const [firstBenefitsHalf, setFirstBenefitsHalf] = useState([])
  const [secondBenefitsHalf, setSecondBenefitsHalf] = useState([])

  const colors = [
    twConfig.theme.colors.benefits['100'],
    twConfig.theme.colors.benefits['200'],
    twConfig.theme.colors.benefits['300'],
    twConfig.theme.colors.benefits['400'],
    twConfig.theme.colors.benefits['500'],
    twConfig.theme.colors.benefits['600'],
    twConfig.theme.colors.benefits['700'],
    twConfig.theme.colors.benefits['800'],
  ]

  useEffect(() => {
    if (benefits?.length >= 2) {
      const middleIndex = Math.floor(benefits.length / 2)
      setFirstBenefitsHalf([...benefits.slice(0, middleIndex)])
      setSecondBenefitsHalf([...benefits.slice(middleIndex)])
    } else {
      setFirstBenefitsHalf(benefits)
    }
  }, [benefits])

  return (
    <section className={styles.sectionWrapper}>
      <div className={styles.benefitsWrapper}>
        {firstBenefitsHalf.length &&
          firstBenefitsHalf.map((benefit, i) => (
            <Benefit
              key={i}
              benefit={benefit}
              color={colors[i % colors.length]}
            />
          ))}
        <div className="relative">
          <ImageComponent
            src={Nyan.src}
            alt="Nyan Cat"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            className="rounded-2xl"
          />
        </div>
        {secondBenefitsHalf.length &&
          secondBenefitsHalf.map((benefit, i) => (
            <Benefit
              key={i}
              benefit={benefit}
              color={
                colors[(Math.trunc(colors.length / 2) + i) % colors.length]
              }
            />
          ))}
      </div>
    </section>
  )
}

export default BenefitsBlock

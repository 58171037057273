
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { NextSeo } from 'next-seo'

import { Layout } from '../components/Layout'
import ContentsWithNavi from '../components/Blocks/ContentsWithNavi'
import Media from '../components/Blocks/Media'
import Hero from '../components/Blocks/Hero'
import PaymentLogoBar from '../components/Blocks/PaymentLogoBar'
import TextMedia from '../components/Blocks/TextMedia'
import CaseStudies from '../components/Blocks/CaseStudies'
import ColoredContainer from '../components/Blocks/ColoredContainer'
import BackgroundContainer from '../components/Blocks/BackgroundContainer'
import Roadmap from '../components/Blocks/Roadmap'
import Facts from '../components/Blocks/Facts'
import TerminalsTwoColumn from '../components/Blocks/TerminalsTwoColumn'
import Cards from '../components/Blocks/Cards'
import PaymentMethods from '../components/Blocks/PaymentMethods'
import VariableWidthContainer from '../components/Blocks/VariableWidthContainer'
import ContactFormBlock from '../components/Blocks/ContactFormBlock'
import {
  getCollectionEntries,
  getCollectionEntry,
} from '../lib/api/page-information'
import { SiteInformationProvider } from '../context/site-information-context'
import { getSiteInformation } from '../lib/api/site-information'
import ThreeImagesAndText from '../components/Blocks/ThreeImagesAndText'
import JobCards from '../components/Blocks/JobCards'
import BenefitsBlock from '../components/Blocks/Benefits'
import LogosBlock from '../components/Blocks/LogosBlock'
import EmployeeBlock from '../components/Blocks/EmployeeBlock'
import BlogTeaser from '../components/Blocks/BlogTeaser'
import HeadlineBlock from '../components/Blocks/HeadlineBlock'
import useTranslation from 'next-translate/useTranslation'
import { stripHtml } from '../lib/cleanString'
import HomeHero from '../components/Blocks/HomeHero'
import TwoColumnText from '../components/Blocks/TwoColumnText'
import TwoImages from '../components/Blocks/TwoImages'
import Quote from '../components/Blocks/Quote'
import Newsletter from '../components/Blocks/Newsletter'
import InstagramFeed from '../components/Blocks/InstagramFeed'
import WYSIWYG from '../components/Blocks/WYSIWYG'
import ApiPageContent from '../components/Blocks/ApiPageContent'
import CodeSnippet from '../components/Blocks/CodeSnippet'

export const BLOCK_MAP = {
  apipagecontentblockset: ApiPageContent,
  homeheroblockset: HomeHero,
  heroblockset: Hero,
  contentswithnaviblockset: ContentsWithNavi,
  mediablockset: Media,
  paymentlogosblockset: PaymentLogoBar,
  textmediablockset: TextMedia,
  coloredcontainerset: ColoredContainer,
  swiperblockset: CaseStudies,
  carouselblockset: Roadmap,
  factsblockset: Facts,
  cardsblockset: Cards,
  terminalstwocolumnblockset: TerminalsTwoColumn,
  backgroundcontainerblockset: BackgroundContainer,
  paymentmethodsblockset: PaymentMethods,
  variablewidthcontainerset: VariableWidthContainer,
  contactformblockset: ContactFormBlock,
  threeimagesblockset: ThreeImagesAndText,
  jobcardsblockset: JobCards,
  benefitsblockset: BenefitsBlock,
  logosblockset: LogosBlock,
  employeeblockset: EmployeeBlock,
  blogteaserblockset: BlogTeaser,
  headlineblockset: HeadlineBlock,
  twocolumntextset: TwoColumnText,
  twoimagesblockset: TwoImages,
  newsletterblockset: Newsletter,
  quoteblockset: Quote,
  instagramfeedblockset: InstagramFeed,
  codeblockset: CodeSnippet,
  text: WYSIWYG,
}

export const DynamicPage = ({ pageInformation = {}, siteInformation = {} }) => {
  const { t } = useTranslation('home')

  return (
    <>
      <SiteInformationProvider siteInformation={siteInformation}>
        <NextSeo
          title={pageInformation?.seo_title || pageInformation?.title || ''}
          description={
            pageInformation?.seo_description || t('intro_subline') || ''
          }
          noindex={pageInformation?.seo_noindex || false}
          nofollow={pageInformation?.seo_nofollow || false}
          openGraph={{
            url: `https://paymenttools.com/${pageInformation?.url}`,
            title: pageInformation?.seo_title || pageInformation?.title || '',
            description:
              stripHtml(pageInformation?.seo_description) ||
              t('intro_subline') ||
              '',
          }}
        />
        <Layout
          headerColor={pageInformation?.header_color?.value || 'white'}
          hideHeader={!pageInformation?.url?.startsWith('/datenschutz')}
          footerWithCareer={pageInformation?.show_footercareer || false}
          pageInformation={pageInformation}
        >
          <div className="flex flex-col items-center">
            {pageInformation?.pagecontent &&
              pageInformation.pagecontent.map((block, i) => {
                const Block = BLOCK_MAP[block.type] || null
                if (Block) {
                  return <Block key={i} content={block} isSection />
                }
              })}
          </div>
        </Layout>
      </SiteInformationProvider>
    </>
  )
}

 const _getStaticProps = async context => {
  const {
    locale = 'de',
    params = {},
    preview = false,
    previewData = {},
  } = context

  const { slug = [] } = params

  const {
    locale: previewLocale = null,
    uri: previewUri = null,
    token: previewToken = null,
  } = previewData || {}

  let pageInformation = null
  try {
    pageInformation =
      (
        await getCollectionEntry(
          'pages',
          preview ? previewLocale : locale,
          preview ? previewUri : `/${slug.join('/')}`,
          preview ? previewToken : null
        )
      )?.data?.data?.entry || null
  } catch (e) {
    console.log(e)
  }

  const {
    data: { data: siteInformation },
  } = await getSiteInformation(locale)

  if (!pageInformation || !siteInformation) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      pageInformation: pageInformation || null,
      siteInformation: siteInformation || null,
    },
    revalidate: 30,
  }
}

export const getStaticPaths = async () => {
  const {
    data: { data },
  } = await getCollectionEntries('pages', null, null)

  // These pages are generated separately
  const excludedPaths = ['jobs', 'blog']

  const paths = data
    .map(page => ({
      params: {
        slug: page.url
          .substring(1)
          .split('/')
          .filter(x => x),
        locale: page.locale,
      },
    }))
    .filter(
      p => p.params.slug.length && !excludedPaths.includes(p.params.slug[0])
    )

  return {
    paths,
    fallback: 'blocking',
  }
}

export default DynamicPage


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[...slug]',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  
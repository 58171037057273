import React, { useState } from 'react'
import cx from 'classnames'
import at from 'lodash/at'
import { useField } from 'formik'
import { Check } from 'react-feather'
import styles from './Input.module.scss'

export const Input = ({
  className,
  type = 'text',
  label,
  required = false,
  ...rest
}) => {
  const [field, meta] = useField(rest)
  const hasError = meta.touched && !!meta.error
  const isValid = meta.touched && !meta.error

  const [hasFocus, setHasFocus] = useState(false)

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error')

    if (touched && error) {
      return error
    }
  }

  return (
    <div>
      <label>
        <span className="sr-only">{label}</span>

        <span className={'block w-full relative'}>
          {isValid && <Check className={'absolute top-6 right-4'} />}

          {hasFocus && (
            <span className={styles.smallLabel}>
              {label}
              {required && '*'}
            </span>
          )}
          <input
            onFocus={() => setHasFocus(true)}
            onBlur={() => setHasFocus(false)}
            type={type}
            placeholder={!hasFocus ? label + (required ? '*' : '') : ''}
            className={cx(
              'block w-full rounded-md focus:outline-none pl-8 pb-4 pr-12',
              {
                'pt-8': hasFocus,
                'pt-4': !hasFocus,
                'border border-purple-input': !hasError,
                'border border-red-500': hasError,
              },
              styles.inputText,
              className
            )}
            // error={meta.touched && meta.error && true}
            {...field}
            {...rest}
          />
        </span>
      </label>
      {hasError && <p className={styles.inputError}>{_renderHelperText()}</p>}
    </div>
  )
}

export default Input

import styles from './Quote.module.scss'
import cx from 'classnames'
import Section from '../../Section'

const Quote = ({ content }) => {
  const {
    quote,
    name,
    job,
    quoteblock_color: { value: color },
    quoteblock_gradient: gradient,
    quoteblock_gradient_color: { value: gradientColor },
    quoteblock_gradient_position: { value: gradientPosition },
  } = content

  return (
    <Section
      classNames={cx(styles.sectionWrapper)}
      color={color}
      gradient={gradient}
      gradientColor={gradientColor}
      gradientPosition={gradientPosition}
    >
      <div className={cx(styles.sectionInner)}>
        <div
          className={cx(styles.quote, {
            'text-yellow': color === 'blue' || color === 'dark-blue',
            'text-purple': color === 'white' || color === 'yellow',
          })}
          dangerouslySetInnerHTML={{ __html: quote }}
        />
        <div
          className={cx(styles.name, {
            'text-white':
              color === 'blue' || color === 'yellow' || color === 'dark-blue',
            'text-purple': color === 'white',
          })}
        >
          {name}
        </div>
        <div
          className={cx(styles.job, {
            'text-white':
              color === 'blue' || color === 'yellow' || color === 'dark-blue',
            'text-purple': color === 'white',
          })}
        >
          {job}
        </div>
      </div>
    </Section>
  )
}

export default Quote

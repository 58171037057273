import React, { useState } from 'react'
import at from 'lodash/at'
import cx from 'classnames'
import { useField } from 'formik'
import styles from './Input.module.scss'

export const Textarea = ({
  className,
  label,
  showCounter = false,
  maxLength = undefined,
  required = false,
  ...rest
}) => {
  const [field, meta] = useField(rest)
  const hasError = meta.touched && meta.error && true

  const [hasFocus, setHasFocus] = useState(false)

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error')

    if (touched && error) {
      return error
    }
  }

  return (
    <div>
      <span className={'block w-full relative'}>
        {hasFocus && (
          <span className={styles.smallLabel}>
            {label}
            {required && '*'}
          </span>
        )}
        <textarea
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          className={cx(
            'block w-full rounded-md focus:outline-none',
            'pl-8 pb-4 pt-8 pr-12',
            {
              'border-sand-darkest': !hasError,
              'border-red-500': hasError,
            },
            styles.inputTextarea,
            className
          )}
          // error={meta.touched && meta.error && true}
          {...field}
          {...rest}
          placeholder={!hasFocus ? label + (required ? '*' : '') : ''}
        />
      </span>
      {showCounter && (
        <div className="flex items-center justify-end my-2">
          {(field && field.value && field.value.length) || 0} / {maxLength}
        </div>
      )}
      {hasError && (
        <p
          className={styles.inputError || 'mt-2 text-red-600 text-14-24'}
          id="text-error"
        >
          {_renderHelperText()}
        </p>
      )}
    </div>
  )
}

export default Textarea

import styles from './BackgroundContainer.module.scss'
import cx from 'classnames'
import ImageComponent from '../../Image'
import cleanString from '../../../lib/cleanString'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Navigation, Pagination } from 'swiper'

const Slide = ({ slide }) => {
  return (
    <div className={styles.slide}>
      {slide && (
        <>
          <div className={styles.overlay} />
          <div
            className={cx(styles.textContent, {
              [styles.small]: slide.small,
            })}
          >
            <h2 className={styles.headline}>{cleanString(slide.headline)}</h2>
            <div className={styles.teaser}>{slide.teaser}</div>
          </div>
          {slide.backgroundimage && slide.backgroundimage?.is_image && (
            <ImageComponent
              layout="fill"
              className="absolute top-0 left-0 object-center object-cover pointer-events-none"
              src={slide.backgroundimage?.permalink}
              objectFit={'cover'}
              alt={''}
            />
          )}
        </>
      )}
    </div>
  )
}

export const BackgroundContainer = ({ content }) => {
  const [isSlider, setIsSlider] = useState(false)
  useEffect(() => {
    setIsSlider(
      content.slides &&
        Array.isArray(content.slides) &&
        content.slides.length > 1
    )
  }, [content])

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  function getSwiperActiveIndex(swipe) {
    let activeIndex = swipe.activeIndex
    let slidesLen = swipe.slides.length
    if (swipe.params.loop) {
      switch (swipe.activeIndex) {
        case 0:
          activeIndex = slidesLen - 3
          break
        case slidesLen - 1:
          activeIndex = 0
          break
        default:
          --activeIndex
      }
    }
    return activeIndex
  }

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.containerInner}>
        {isSlider ? (
          <>
            <div className={styles.swiper}>
              <Swiper
                modules={[Pagination, Navigation, Keyboard]}
                loop={true}
                slidesPerView={1}
                spaceBetween={16}
                autoplay={true}
                speed={750}
                keyboard={{
                  enabled: true,
                  onlyInViewport: true,
                }}
                navigation={true}
                autoHeight={true}
                pagination={{
                  el: `.${styles.swiperPagination}`,
                  clickable: true,
                  renderBullet: function (index, className) {
                    return `<span class="${cx(
                      className,
                      styles.swiperPaginationBullet,
                      {
                        [styles.active]: activeSlideIndex === index,
                      }
                    )}"></span>`
                  },
                }}
                onSlideChange={swipe => {
                  setActiveSlideIndex(getSwiperActiveIndex(swipe))
                }}
              >
                {content.slides &&
                  content.slides.map((slide, index) => (
                    <SwiperSlide key={index} index={index}>
                      <Slide slide={slide} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
            <div className={styles.swiperPagination} />
          </>
        ) : (
          <Slide slide={content?.slides?.[0]} />
        )}
      </div>
    </div>
  )
}

export default BackgroundContainer

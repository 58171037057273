import styles from './RoadmapSlide.module.scss'
import cx from 'classnames'
import { SwiperSlide } from 'swiper/react'
import ImageComponent from '../../../Image'
import cleanString from '../../../../lib/cleanString'

export const RoadmapSlide = ({ content, className, index, order }) => {
  const { icon: icon, headline: headline, teaser: teaser } = content

  return (
    <SwiperSlide
      className={cx(styles.slide, className, {
        'bg-purple-dark-slide': index % 3 === 2,
        'bg-purple': index % 3 === 0,
        'bg-purple-light': index % 3 === 1,
      })}
    >
      {icon && (
        <div className={styles.icon}>
          <ImageComponent
            src={icon.permalink}
            layout="fixed"
            width={70}
            height={Math.ceil(70 / icon?.ratio ?? 1)}
            quality={80}
            objectFit="contain"
            alt=""
          />
        </div>
      )}
      {order && <div className="text-yellow">{order}</div>}
      <h3
        className={styles.headline}
        dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
      />
      <div
        className={styles.teaser}
        dangerouslySetInnerHTML={{ __html: teaser }}
      />
    </SwiperSlide>
  )
}

RoadmapSlide.displayName = 'SwiperSlide'

export default RoadmapSlide

import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

import Pattern1 from '../../../public/svg/newsletter/Pattern1.svg'
import Pattern2 from '../../../public/svg/newsletter/Pattern2.svg'
import Hand from '../../../public/svg/newsletter/hand.svg'

import Section from '../../Section'
import Button from '../../Button'

import { Loader, Check, X } from 'react-feather'

import styles from './Newsletter.module.scss'

const Newsletter = ({ content }) => {
  const [submitted, setSubmitted] = useState(false)
  const [success, setSuccess] = useState(false)
  const [responseReceived, setResponseReceived] = useState(false)

  const {
    newsletter_color: { value: color },
    newsletter_gradient: gradient,
    newsletter_gradient_color: { value: gradientColor },
    newsletter_gradient_position: { value: gradientPosition },
    headline,
    teaser,
  } = content

  const { t } = useTranslation('common')

  const handleReset = () => {
    setSubmitted(false)
    setSuccess(false)
    setResponseReceived(false)
  }

  return (
    <Section
      color={color}
      gradient={gradient}
      gradientColor={gradientColor}
      gradientPosition={gradientPosition}
    >
      <div className="container">
        <div className={styles.newsletter}>
          <div className={styles.patternWrapper}>
            <Pattern1 className={styles.pattern1} />
            <Pattern2 className={styles.pattern2} />
          </div>
          <div className={styles.contentWrapper}>
            <Hand className={styles.hand} />
            <div
              className={styles.headline}
              dangerouslySetInnerHTML={{ __html: headline }}
            />
            <div
              className={styles.teaser}
              dangerouslySetInnerHTML={{ __html: teaser }}
            />

            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Invalid email').required('Required'),
              })}
              onSubmit={async values => {
                setSubmitted(true)
                try {
                  await axios.post('/api/newsletter', {
                    values,
                  })
                  setResponseReceived(true)
                  setSuccess(true)
                } catch (e) {
                  setResponseReceived(true)
                  setSuccess(false)
                }
              }}
            >
              {submitted && !responseReceived ? (
                <Loader className={styles.loadingSpinner} />
              ) : submitted && responseReceived && success ? (
                <Check className={styles.checkmark} />
              ) : submitted && responseReceived && !success ? (
                <>
                  <X className={styles.x} />
                  <div className={styles.retryWrapper}>
                    <Button
                      className={styles.retryButton}
                      small
                      onClick={handleReset}
                    >
                      {t('retry')}
                    </Button>
                  </div>
                </>
              ) : (
                <Form className={styles.form}>
                  <Field
                    id="email"
                    name="email"
                    placeholder={t('your-mail')}
                    type="email"
                  />

                  <Button type="submit" small>
                    {t('subscribe')}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Newsletter

import { JobCard } from './JobCard'
import { useJobPublications } from '../../../lib/data/use-jobs'
import styles from './JobCards.module.scss'
import CustomLink from '../CustomLink'
import { useEffect, useState } from 'react'
import cleanString from '../../../lib/cleanString'
import useTranslation from 'next-translate/useTranslation'

export const JobCards = ({ content }) => {
  const { lang } = useTranslation()

  const [jobs, setJobs] = useState([])
  const {
    headline,
    link: customLink,
    linktext: customLinkText,
    linktype: customLinkType,
    newtab: customLinkNewTab,
    color: customLinkColor,
  } = content

  const { jobs: jobPublications } = useJobPublications(lang, {
    revalidateOnMount: true,
  })

  useEffect(() => {
    if (jobPublications?.length > 8) {
      setJobs(
        jobPublications
          .filter(job => job[lang]?.title?.length <= 30)
          .sort(function (a, b) {
            return new Date(b.created) - new Date(a.created)
          })
          .slice(0, 8)
      )
    }
  }, [jobPublications, setJobs])

  return (
    <section className={styles.sectionWrapper}>
      <h2
        className={styles.headline}
        dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
      />
      <div className={styles.cardsWrapper}>
        {jobs?.map((job, i) => (
          <JobCard key={i} href="/" job={job[lang]} />
        ))}
      </div>

      {customLink && (
        <CustomLink
          linkType={customLinkType}
          link={customLink}
          text={customLinkText}
          newTab={customLinkNewTab}
          color={customLinkColor.value}
        >
          {customLinkText}
        </CustomLink>
      )}
    </section>
  )
}

export default JobCards

import styles from './ContactFormBlock.module.scss'
import { Check } from 'react-feather'
import useTranslation from 'next-translate/useTranslation'
import { ContactForm } from './ContactForm'
import { useRef, useState } from 'react'
import Button from '../../Button'
import cx from 'classnames'

export const ContactFormBlock = ({ content }) => {
  const { t } = useTranslation('contact')
  const formikRef = useRef()

  const [messageSent, setMessageSent] = useState(false)

  const { headline, features, address, privacy } = content

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.containerInner}>
        <div className={styles.info}>
          <div
            className={styles.headline}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
          <div className={styles.featureList}>
            <ul>
              {features.map((feature, index) => (
                <li className={styles.featureItem} key={index}>
                  <Check /> {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.address}>
            <hr className="mb-16 border border-gray-hr" />
            <div dangerouslySetInnerHTML={{ __html: address }} />
            <hr className="block lg:hidden mt-16 border border-gray-hr" />
          </div>
        </div>
        <div
          className={cx(styles.form, {
            [styles.messageSent]: messageSent,
          })}
        >
          <main>
            {messageSent ? (
              <div className="w-full h-full flex items-center flex-col">
                <div className="text-45-55 text-center whitespace-pre font-medium mb-8">
                  {t('sent.heading')}
                </div>
                <div className="text-16-28-big text-center max-w-lg mb-8">
                  {t('sent.text')}
                </div>
                <div className="flex items-center space-x-4">
                  <Button href={'/'} as="a">
                    {t('sent.button_back')}
                  </Button>

                  <Button href={'/jobs'} as="a" color={'white'}>
                    {t('sent.button_apply')}
                  </Button>
                </div>
              </div>
            ) : (
              <ContactForm
                formikRef={formikRef}
                setMessageSent={setMessageSent}
                privacyContent={privacy}
              />
            )}
          </main>
        </div>
      </div>
    </div>
  )
}

export default ContactFormBlock

import styles from './ScrollTopButton.module.scss'
import { ChevronUp } from 'react-feather'

export default function ScrollTopButton() {
  const jumpToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <ChevronUp onClick={jumpToTop} />
    </div>
  )
}

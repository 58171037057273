import styles from './ColoredContainer.module.scss'
import cx from 'classnames'

import { BLOCK_MAP } from '../../../pages/[...slug]'
import CustomLink from '../CustomLink'
import cleanString from '../../../lib/cleanString'

export const ColoredContainer = ({ content }) => {
  let {
    container_color,
    headline,
    items,
    teaser,
    color: linkColor,
    link,
    linktext,
    newtab,
    linktype,
    alignment,
  } = content

  if (!alignment.value) {
    alignment.value = 'center'
  }

  return (
    <div className={styles.containerWrapper}>
      <div
        className={cx(styles.containerInner, {
          'bg-white text-purple': container_color.value === 'white',
          'bg-yellow text-purple': container_color.value === 'yellow',
          'bg-purple-dark text-white': container_color.value === 'blue',
          'bg-gray-lighter text-purple': container_color.value === 'gray',
          [styles.noContent]: !items?.length,
        })}
      >
        <div
          className={cx(styles.headlineWrapper, {
            [styles.noContent]: !items?.length,
            [styles.alignLeft]: alignment.value === 'left',
            [styles.alignRight]: alignment.value === 'right',
            [styles.alignCenter]: alignment.value === 'center',
          })}
        >
          <h2
            className={styles.headline}
            dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
          />
          {teaser && (
            <div
              className={styles.teaser}
              dangerouslySetInnerHTML={{ __html: teaser }}
            />
          )}
        </div>
        {items &&
          items.map((block, i) => {
            const Block = BLOCK_MAP[block.type] || null
            if (Block) {
              return (
                <Block
                  key={i}
                  content={block}
                  isSection={false}
                  inColoredContainer
                />
              )
            }
          })}
        <div className={'w-full flex justify-center'}>
          {link && (
            <CustomLink
              className={'mt-8'}
              link={link}
              linktype={linktype}
              color={linkColor?.value}
              newtab={newtab}
            >
              {linktext}
            </CustomLink>
          )}
        </div>
      </div>
    </div>
  )
}

export default ColoredContainer

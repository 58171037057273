import useTranslation from 'next-translate/useTranslation'

export const useFormModel = () => {
  const { t } = useTranslation('contact')

  return {
    formId: 'contactForm',
    formField: {
      firstName: {
        name: 'firstName',
        label: t('form.firstName.label'),
        requiredErrorMsg: t('form.firstName.errors.required'),
        required: true,
      },
      lastName: {
        name: 'lastName',
        label: t('form.lastName.label'),
        requiredErrorMsg: t('form.lastName.errors.required'),
        required: true,
      },
      email: {
        name: 'email',
        label: t('form.email.label'),
        requiredErrorMsg: t('form.email.errors.required'),
        invalidErrorMsg: t('form.email.errors.invalid'),
        required: true,
      },
      phone: {
        name: 'phone',
        label: t('form.phone.label'),
        requiredErrorMsg: t('form.phone.errors.required'),
        invalidErrorMsg: t('form.phone.errors.invalid'),
        required: false,
      },
      subject: {
        name: 'subject',
        label: t('form.subject.label'),
        requiredErrorMsg: t('form.subject.errors.required'),
        required: true,
      },
      message: {
        name: 'message',
        label: t('form.message.placeholder'),
        required: true,
      },
    },
  }
}

export default useFormModel

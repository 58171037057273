import styles from './Terminal.module.scss'
import cleanString from '../../../../lib/cleanString'
import { Check } from 'react-feather'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper'
import ImageComponent from '../../../Image'
import useTranslation from 'next-translate/useTranslation'
import cx from 'classnames'

export const Terminal = ({ content = null, className = '' }) => {
  const {
    title,
    paymentmethods: paymentMethods,
    productphotos: photos,
    subtitle: subtitle,
    terminalfeatures: features,
  } = content

  const { t } = useTranslation('paymentmethods')

  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const [mainPhoto, setMainPhoto] = useState(photos?.[0] || null)
  useEffect(() => {
    setMainPhoto(photos?.[0] || null)
  }, [photos])

  return (
    <div className={cx(styles.terminalWrapper, className)}>
      <div className={styles.photoWrapper}>
        <Swiper
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
          }}
          loop={false}
          spaceBetween={0}
          navigation={true}
          autoHeight={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          {photos.map((photo, index) => (
            <SwiperSlide key={index}>
              <div
                className={cx(styles.photo, {
                  [styles.isMain]: photo.id === mainPhoto.id,
                  [styles.isSecondary]: photo.id !== mainPhoto.id,
                })}
              >
                <ImageComponent
                  src={photo.permalink}
                  alt={photo.alt}
                  quality={80}
                  layout="fill"
                  className="rounded-2xl h-full"
                  objectFit="contain"
                  objectPosition={photo.focus_css}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {photos.length > 1 && (
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={false}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper mt-4"
          >
            {photos.map((photo, index) => (
              <SwiperSlide key={index}>
                <div
                  className={cx(styles.photoSmall, {
                    [styles.isMain]: photo.id === mainPhoto.id,
                    [styles.isSecondary]: photo.id !== mainPhoto.id,
                  })}
                >
                  <ImageComponent
                    src={photo.permalink}
                    alt={photo.alt}
                    quality={80}
                    layout="fill"
                    className="rounded-2xl h-full"
                    objectFit="contain"
                    objectPosition={photo.focus_css}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: cleanString(title) }}
      />
      <div
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: cleanString(subtitle) }}
      />
      <div className={styles.featureWrapper}>
        <ul className={styles.featureList}>
          {features.map((feature, index) => (
            <li key={index}>
              <Check /> {feature.title}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.paymentMethodsWrapper}>
        <div className={styles.heading}>{t('title')}:</div>
        <div
          className={styles.paymentMethods}
          dangerouslySetInnerHTML={{ __html: cleanString(paymentMethods) }}
        />
      </div>
    </div>
  )
}

export default Terminal

import React, { useState } from 'react'
import cx from 'classnames'
import at from 'lodash/at'
import { useField } from 'formik'
import styles from './Input.module.scss'

export const Select = ({
  className,
  label,
  options,
  required = false,
  ...rest
}) => {
  const [hasFocus, setHasFocus] = useState(false)

  const [field, meta] = useField(rest)
  const hasError = meta.touched && meta.error && true

  const inputCx = cx(
    'block w-full rounded-md focus:outline-none focus:ring-yellow focus:border-yellow',
    {
      'border-sand-darkest': !hasError,
      'border-red-500': hasError,
    },
    className
  )

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error')

    if (touched && error) {
      return error
    }
  }

  return (
    <div>
      <label>
        <span className="sr-only">{label}</span>

        <span className="block w-full relative">
          {hasFocus && (
            <span className={styles.smallLabel}>
              {label}
              {required && '*'}
            </span>
          )}
          <select
            onFocus={() => !hasFocus && setHasFocus(true)}
            onBlur={() => hasFocus && setHasFocus(false)}
            className={cx(
              inputCx,
              styles.inputSelect,
              styles.inputText,
              className,
              'pl-8 text-purple',
              {
                'pt-8': hasFocus,
                'pt-2': !hasFocus,
              }
            )}
            {...field}
            {...rest}
          >
            <option value="" disabled hidden>
              {hasFocus ? '' : label + (required ? '*' : '')}
            </option>
            {options &&
              options.map(option => (
                <option
                  key={option.value}
                  className={styles.inputSelectOption}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
          </select>
        </span>
      </label>
      {hasError && (
        <p className={styles.inputError || 'mt-2 text-red-600 text-14-24'}>
          {_renderHelperText()}
        </p>
      )}
    </div>
  )
}

export default Select

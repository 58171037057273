import { useFormModel } from './formModel'

export const useInitialValues = () => {
  const {
    formField: { firstName, lastName, email, subject, message, phone },
  } = useFormModel()

  return {
    [firstName.name]: '',
    [lastName.name]: '',
    [email.name]: '',
    [phone.name]: '',
    [subject.name]: '',
    [message.name]: '',
  }
}
export default useInitialValues

import styles from './TwoColumnText.module.scss'
import cx from 'classnames'
import Section from '../../Section'

const TwoColumnText = ({ content }) => {
  const {
    text_left: textLeft,
    text_right: textRight,
    twocolumntext_color: { value: color },
    twocolumntext_gradient: gradient,
    twocolumntext_gradient_color: { value: gradientColor },
    twocolumntext_gradient_position: { value: gradientPosition },
  } = content

  return (
    <Section
      classNames={styles.twoColumnText}
      color={color}
      gradient={gradient}
      gradientColor={gradientColor}
      gradientPosition={gradientPosition}
    >
      <div className={styles.twoColumnTextInner}>
        <div
          className={cx(styles.textLeft, {
            'text-white':
              color === 'yellow' || color === 'blue' || color === 'dark-blue',
          })}
          dangerouslySetInnerHTML={{ __html: textLeft }}
        />
        <div
          className={cx(styles.textRight, {
            'text-white':
              color === 'yellow' || color === 'blue' || color === 'dark-blue',
            'text-purple': color === 'white',
          })}
          dangerouslySetInnerHTML={{ __html: textRight }}
        />
      </div>
    </Section>
  )
}

export default TwoColumnText

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import styles from './Tooltip.module.css'

const Tooltip = ({
  children,
  content,
  open,
  defaultOpen = false,
  onOpenChange,
  side = 'top',
  arrow = true,
  ...props
}) => {
  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={0}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Content
        side={side}
        align="center"
        className={styles.tooltipContent}
        {...props}
      >
        {content}
        {arrow ? (
          <TooltipPrimitive.Arrow
            className={styles.tooltipArrow}
            offset={5}
            width={11}
            height={5}
          />
        ) : null}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  )
}

export default Tooltip

import styles from './Roadmap.module.scss'
import cx from 'classnames'
import { Swiper } from 'swiper/react'
import { Keyboard, Navigation, Pagination } from 'swiper'
import RoadmapSlide from './RoadmapSlide'
import { useEffect, useState } from 'react'

export const Roadmap = ({ content, className }) => {
  const { items: items, slidesperview, withcount, loop = true } = content

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    // HACK: You can't replace the breakpoints property directly on the swiper instance.
    if (swiper) {
      const slidesPerView = parseInt(slidesperview.label)
      swiper.params.breakpoints[640].slidesPerView =
        slidesPerView - 1 >= 1 ? slidesPerView - 1 : 1
      swiper.params.breakpoints[640].slidesPerGroup = 1
      swiper.params.breakpoints[1024].slidesPerView = slidesPerView
      swiper.params.breakpoints[1024].slidesPerGroup = 1
      swiper.currentBreakpoint = false
      swiper.update()
    }
  }, [slidesperview.label, swiper])

  function getSwiperActiveIndex(swipe) {
    let activeIndex = swipe.activeIndex
    let slidesLen = swipe.slides.length
    if (swipe.params.loop) {
      switch (swipe.activeIndex) {
        case 0:
          activeIndex = slidesLen - 3
          break
        case slidesLen - 1:
          activeIndex = 0
          break
        default:
          --activeIndex
      }
    }
    return activeIndex
  }

  return (
    <div className={cx(styles.sectionWrapper, className)}>
      <div className={styles.swiper}>
        <Swiper
          onSwiper={swipe => {
            setSwiper(swipe)
          }}
          modules={[Pagination, Navigation, Keyboard]}
          loop={loop}
          spaceBetween={16}
          keyboard={{
            enabled: true,
            onlyInViewport: true,
          }}
          navigation={true}
          pagination={{
            el: `.${styles.swiperPagination}`,
            clickable: true,
            renderBullet: function (index, className) {
              return `<span class="${cx(
                className,
                styles.swiperPaginationBullet,
                {
                  [styles.active]: activeSlideIndex === index,
                }
              )}"></span>`
            },
          }}
          centeredSlides={true}
          onSlideChange={swipe => {
            setActiveSlideIndex(getSwiperActiveIndex(swipe))
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            1024: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
          }}
        >
          {items &&
            items.map((item, index) => (
              <RoadmapSlide
                content={item}
                key={index}
                index={index}
                order={withcount ? `${index + 1}/${items.length}` : null}
              />
            ))}
        </Swiper>
      </div>
      <div className={styles.swiperPagination} />
    </div>
  )
}

export default Roadmap

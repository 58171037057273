import styles from './PaymentMethods.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { Check, Plus } from 'react-feather'
import { useContext, useEffect, useState } from 'react'
import { SiteInformationContext } from '../../../context/site-information-context'
import ImageComponent from '../../Image'
import cleanString from '../../../lib/cleanString'

const PaymentMethod = ({ item }) => {
  const { iconbackgroundcolor, iconcolor, title, features } = item

  const { t } = useTranslation('paymentmethods')

  return (
    <div className={styles.methodWrapper}>
      <div
        className={styles.methodImageWrapper}
        style={{ backgroundColor: iconbackgroundcolor }}
      >
        <ImageComponent
          src={iconcolor.permalink}
          alt={iconcolor.alt}
          width={iconcolor.width}
          height={iconcolor.height}
          layout="intrinsic"
        />
      </div>
      {title && (
        <h3
          className="mb-7"
          dangerouslySetInnerHTML={{ __html: cleanString(title) }}
        />
      )}
      <ol className={'p-0 m-0'}>
        {features?.length &&
          features.map((feat, i) => (
            <li className="flex" key={i}>
              <Check className={styles.listIcon} />
              {t(feat.value)}
            </li>
          ))}
      </ol>
    </div>
  )
}

export const PaymentMethods = ({ content }) => {
  const { items: itemIds, lastitemtext: lastItemText } = content

  const siteInformation = useContext(SiteInformationContext)

  const [displayItems, setDisplayItems] = useState(
    siteInformation?.payment_methods?.data?.filter(
      item => itemIds.find(id => id === item.id) && !!item.iconcolor
    ) || []
  )
  useEffect(() => {
    setDisplayItems(
      siteInformation?.payment_methods?.data?.filter(
        item => itemIds.find(id => id === item.id) && !!item.iconcolor
      ) || []
    )
  }, [itemIds, siteInformation?.payment_methods?.data])

  return (
    <section className={styles.sectionWrapper}>
      {displayItems?.length
        ? itemIds.map((itemId, i) => {
            const item = displayItems.find(id => id.id === itemId)
            return <PaymentMethod key={i} item={item} />
          })
        : ''}
      <div className={styles.lastItemWrapper}>
        <a href={'/kontakt'}>
          <div className={styles.plusIconWrapper}>
            <Plus className="text-white w-[52px] h-[52px] fill-purple" />
          </div>
        </a>
        {lastItemText && (
          <div
            className="text-white text-center text-24-35"
            dangerouslySetInnerHTML={{ __html: lastItemText }}
          />
        )}
      </div>
    </section>
  )
}

export default PaymentMethods

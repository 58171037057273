import styles from './JobCard.module.scss'

import useTranslation from 'next-translate/useTranslation'
import cleanString from '../../../../lib/cleanString'

export const JobCard = ({ job }) => {
  const { lang } = useTranslation()
  const href = job._type === 'cta' ? '/jobs' : `/jobs/${job._slug}`

  job = job[lang] || job

  return (
    <a href={href} className={styles.card} data-locale={lang}>
      <div className={styles.cardInner}>
        <div className={styles.upperText}>
          <div className={'mb-2'}>{job.field}</div>
          <h3 className={styles.headline}>{cleanString(job.title)}</h3>
        </div>
        <div className={'mb-2'}>
          <p>{job.intro}</p>
        </div>
      </div>
    </a>
  )
}

export default JobCard

import styles from './ContentsWithNavi.module.scss'
import { createRef, useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import ScrollTopButton from '../../ScrollTopButton'
import cleanString from '../../../lib/cleanString'

export const ContentsWithNavi = ({ content = null }) => {
  const sectionCount = content?.toplevel_sections?.length || 0
  const sectionIndices = [...Array(sectionCount).keys()]

  const contentRefs = useRef(sectionIndices.map(() => createRef()))

  const [jumpTargets, _setJumpTargets] = useState([])
  const jumpTargetsRef = useRef(jumpTargets)
  const setJumpTargets = data => {
    jumpTargetsRef.current = data
    _setJumpTargets(data)
  }

  const [activeSectionIndex, _setActiveSectionIndex] = useState(null)
  const activeSectionIndexRef = useRef(activeSectionIndex)
  const setActiveSectionIndex = data => {
    activeSectionIndexRef.current = data
    _setActiveSectionIndex(data)
  }

  const [firstVisibleJumpTarget, _setFirstVisibleJumpTarget] = useState(null)
  const firstVisibleJumpTargetRef = useRef(firstVisibleJumpTarget)
  const setFirstVisibleJumpTarget = data => {
    firstVisibleJumpTargetRef.current = data
    _setFirstVisibleJumpTarget(data)
  }

  const [lastVisibleJumpTarget, _setLastVisibleJumpTarget] = useState(null)
  const lastVisibleJumpTargetRef = useRef(lastVisibleJumpTarget)
  const setLastVisibleJumpTarget = data => {
    lastVisibleJumpTargetRef.current = data
    _setLastVisibleJumpTarget(data)
  }

  const updateAnchors = () => {
    if (!jumpTargetsRef?.current || !jumpTargetsRef?.current?.length) {
      // remove existing anchors if any
      const existingAnchors = contentRefs.current.map(contentRef => {
        return contentRef.current.querySelectorAll('a.anchorLink')
      })

      existingAnchors.forEach(anchors =>
        anchors.forEach(anchor => anchor.remove())
      )

      // add new anchors
      setJumpTargets(
        contentRefs.current.map((contentRef, refIndex) => {
          const targets = []
          const h3elements = contentRef?.current?.querySelectorAll('h3')

          for (let j = 0; j < h3elements.length; j++) {
            const anchor = document.createElement('a')
            anchor.classList.add(styles.anchorTarget)
            anchor.setAttribute('href', '#')
            anchor.setAttribute('id', `section-${refIndex + 1}-${j + 1}`)
            contentRef?.current?.insertBefore(anchor, h3elements[j])

            targets.push({
              el: anchor,
              label: h3elements[j].innerText,
            })
          }

          return targets
        })
      )
    }
  }

  const checkActiveTarget = useCallback(() => {
    updateAnchors()

    const visibleJumpTargetsBySection = sectionIndices.map(
      sectionIndex =>
        jumpTargetsRef?.current?.[sectionIndex]?.filter(
          jumpTarget =>
            jumpTarget.el.getBoundingClientRect().top > 0 &&
            jumpTarget.el.getBoundingClientRect().top < window.innerHeight
        ) || []
    )

    const indexOfSectionWithVisibleJumpTarget =
      visibleJumpTargetsBySection.findIndex(
        visibleJumpTargets => visibleJumpTargets.length
      )

    if (indexOfSectionWithVisibleJumpTarget === -1) {
      setFirstVisibleJumpTarget(lastVisibleJumpTargetRef.current)
    } else {
      setLastVisibleJumpTarget(firstVisibleJumpTargetRef.current)
      setFirstVisibleJumpTarget(
        visibleJumpTargetsBySection?.[indexOfSectionWithVisibleJumpTarget][0]
      )
      setActiveSectionIndex(indexOfSectionWithVisibleJumpTarget)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstVisibleJumpTargetRef, jumpTargetsRef, lastVisibleJumpTargetRef])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', checkActiveTarget)
      window.addEventListener('scroll', checkActiveTarget)
    }
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  useEffect(() => checkActiveTarget(), [contentRefs])

  return (
    <div className={styles.sectionWrapper}>
      {content.headline && (
        <h2
          className={styles.headline}
          dangerouslySetInnerHTML={{ __html: cleanString(content.headline) }}
        />
      )}
      <div className={styles.sectionInner}>
        <div className={styles.indexWrapper}>
          {content.toplevel_sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className={'mb-8'}>
              <a href={`#section-${sectionIndex + 1}`}>
                <div
                  className={'text-20-30 font-semibold'}
                  dangerouslySetInnerHTML={{ __html: section.headline }}
                />
              </a>

              {jumpTargetsRef?.current?.[sectionIndex]?.map(
                (target, targetIndex) => (
                  <a
                    key={targetIndex}
                    href={`#${target.el.id}`}
                    className={cx(styles.anchorLink, {
                      [styles.active]:
                        target.el.id ===
                          firstVisibleJumpTargetRef.current?.el.id ||
                        target.el.id ===
                          lastVisibleJumpTargetRef.current?.el.id,
                      [styles.noDisplay]:
                        sectionIndex !== activeSectionIndexRef?.current,
                    })}
                  >
                    {target.label}
                  </a>
                )
              )}
            </div>
          ))}
        </div>
        <div className={styles.contentWrapper}>
          {content?.toplevel_sections?.map((section, index) => (
            <div key={index}>
              <div>
                <a
                  id={`section-${index + 1}`}
                  className={styles.anchorTarget}
                />
              </div>
              <h2 dangerouslySetInnerHTML={{ __html: section.headline }} />
              <div
                ref={contentRefs.current[index]}
                dangerouslySetInnerHTML={{
                  __html: section.contents,
                }}
              />
            </div>
          ))}
        </div>
        <ScrollTopButton />
      </div>
    </div>
  )
}

export default ContentsWithNavi

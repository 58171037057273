import styles from './BlogTeaser.module.scss'
import cx from 'classnames'
import { useMemo } from 'react'
import { useBlogposts } from '../../../lib/data/use-blogposts'
import useTranslation from 'next-translate/useTranslation'
import { BlogpostPreviewCard } from '../../BlogpostPreviewCard'
import moment from 'moment'

const BlogTeaser = ({
  staticBlogposts,
  excludeIds = [],
  inColoredContainer,
  className,
}) => {
  const { lang } = useTranslation('blog')

  const { blogposts } = useBlogposts(
    {
      locale: lang,
      previewToken: null,
    },
    {
      fallbackData: staticBlogposts,
      revalidateOnMount: true,
    }
  )

  const newestPosts = useMemo(
    () =>
      blogposts
        ?.filter(bp => !excludeIds.includes(bp.id))
        ?.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1))
        ?.slice(0, 2) || [],
    [blogposts, excludeIds]
  )

  return (
    <div
      className={cx(
        {
          [styles.inColoredContainer]: inColoredContainer,
          ['styles.sectionWrapper']: !inColoredContainer,
        },
        className
      )}
    >
      {newestPosts?.length > 1 && (
        <div className={styles.wrapperInner}>
          <BlogpostPreviewCard blogpost={newestPosts[0]} className="lg:w-1/2" />
          <div className={styles.separator} />
          <BlogpostPreviewCard blogpost={newestPosts[1]} className="lg:w-1/2" />
        </div>
      )}
    </div>
  )
}

export default BlogTeaser

import styles from './CodeSnippet.module.scss'
import Highlight, { defaultProps } from 'prism-react-renderer'
import { Clipboard } from 'react-feather'
import copyTextToClipboard from '../../../lib/copyTextToClipboard'
import Tooltip from '../../Tooltip'
import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'

const CodeSnippet = ({ content }) => {
  const { codesnippetblock } = content
  const { t } = useTranslation('common')

  const [showCopyTooltip, setShowCopyTooltip] = useState(false)

  return (
    <div className={styles.codeSnippet}>
      <Highlight {...defaultProps} code={codesnippetblock.value} language="jsx">
        {({ className, tokens, getLineProps, getTokenProps }) => (
          <>
            <div
              className={styles.copyButton}
              onClick={() => {
                copyTextToClipboard(
                  codesnippetblock.value.replace('„', '"').replace('“', '"')
                )
                setShowCopyTooltip(true)
                setTimeout(() => {
                  setShowCopyTooltip(false)
                }, 1000)
              }}
            >
              <div className={'flex items-center gap-2'}>
                <Clipboard width={16} /> <span>copy</span>
              </div>
              <div>
                <Tooltip
                  content={t('codesnipped_copied')}
                  open={showCopyTooltip}
                  side={'bottom'}
                  arrow={false}
                >
                  <span></span>
                </Tooltip>
              </div>
            </div>

            <pre className={className}>
              <code>
                {tokens.map((line, i) => (
                  <div {...getLineProps({ line, key: i })} style={{}} key={i}>
                    {line.map((token, key) => (
                      <span
                        {...getTokenProps({ token, key })}
                        style={{}}
                        key={key}
                      />
                    ))}
                  </div>
                ))}
              </code>
            </pre>
          </>
        )}
      </Highlight>
    </div>
  )
}

export default CodeSnippet

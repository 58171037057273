import styles from './VariableWidthContainer.module.scss'
import { BLOCK_MAP } from '../../../pages/[...slug]'
import CustomLink from '../CustomLink'
import Media from '../Media'
import cx from 'classnames'
import Section from '../../Section'

export const VariableWidthContainer = ({ content }) => {
  const {
    widthpercent,
    contents,
    variablewidthcontainer_color: { value: color },
    variablewidthcontainer_gradient: gradient,
    variablewidthcontainer_gradient_color: { value: gradientColor },
    variablewidthcontainer_gradient_position: { value: gradientPosition },
  } = content

  return (
    <Section
      classNames={cx(styles.containerWrapper, {
        'text-white': color === 'blue',
        'text-purple': color === 'yellow' || color === 'white',
      })}
      color={color}
      gradient={gradient}
      gradientColor={gradientColor}
      gradientPosition={gradientPosition}
    >
      <div className={styles.containerInner} data-width={widthpercent}>
        {contents?.map((block, i) => {
          if (block.type === 'linkset') {
            return (
              <CustomLink
                key={i}
                linkType={block.linktype}
                link={block.link}
                newTab={block.newtab}
                color={block.color}
                className="mt-8"
              >
                {block.linktext}
              </CustomLink>
            )
          } else if (block.type === 'mediablock') {
            return <Media key={i} content={block} />
          } else if (block.type === 'text') {
            return (
              <div key={i} dangerouslySetInnerHTML={{ __html: block.text }} />
            )
          } else {
            const Block = BLOCK_MAP[block.type] || null
            if (Block) {
              return (
                <Block
                  key={i}
                  content={block}
                  isSection={false}
                  inColoredContainer
                />
              )
            }
          }
        })}
      </div>
    </Section>
  )
}

export default VariableWidthContainer

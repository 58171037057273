import * as Yup from 'yup'
import { useFormModel } from './formModel'

export const useValidationSchema = () => {
  const {
    formField: { firstName, lastName, email, subject, message, phone },
  } = useFormModel()

  return Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
    [email.name]: Yup.string()
      .email(`${email.invalidErrorMsg}`)
      .required(`${email.requiredErrorMsg}`),
    [phone.name]: Yup.string(),
    [subject.name]: Yup.string()
      .required(`${subject.requiredErrorMsg}`)
      .notOneOf([-1, '']),
    [message.name]: Yup.string().notRequired(),
  })
}

export default useValidationSchema

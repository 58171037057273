import cx from 'classnames'
import ImageComponent from '../../Image'

import styles from './TwoImages.module.scss'
import Section from '../../Section'

const TwoImages = ({ content }) => {
  const {
    image_left: imageLeft,
    image_right: imageRight,
    variant: { value: variant },
    twoimages_color: { value: color },
    twoimages_gradient: gradient,
    twoimages_gradient_color: { value: gradientColor },
    twoimages_gradient_position: { value: gradientPosition },
  } = content

  return (
    <Section
      classNames={styles.twoImages}
      color={color}
      gradient={gradient}
      gradientColor={gradientColor}
      gradientPosition={gradientPosition}
    >
      <div
        className={cx(styles.twoImagesInner, {
          'sm:items-end': variant === 'overflow-left',
          'sm:items-start': variant === 'overflow-right',
          '': variant === 'no-overflow',
        })}
      >
        <div
          className={cx({
            [styles.imageLeftOverflowLeft]: variant === 'overflow-left',
            [styles.imageLeftOverflowRight]: variant === 'overflow-right',
            [styles.imageLeftNoOverflow]: variant === 'no-overflow',
          })}
        >
          <div>
            <ImageComponent
              src={imageLeft.permalink}
              alt={imageLeft.alt}
              layout="fill"
              className="rounded-2xl"
            />
          </div>
        </div>
        <div
          className={cx({
            [styles.imageRightOverflowLeft]: variant === 'overflow-left',
            [styles.imageRightOverflowRight]: variant === 'overflow-right',
            [styles.imageRightNoOverflow]: variant === 'no-overflow',
          })}
        >
          <div>
            <ImageComponent
              src={imageRight.permalink}
              alt={imageRight.alt}
              layout="fill"
              className="rounded-2xl"
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TwoImages

import cx from 'classnames'
import styles from './ThreeImagesAndText.module.scss'
import ImageComponent from '../../Image'

const ThreeImagesAndText = ({ content }) => {
  const { images, teaser } = content

  return (
    <section className={styles.sectionWrapper}>
      <div className={styles.sectionInner}>
        <div className={cx(styles.firstImage)}>
          {images?.length && images[0] && (
            <ImageComponent
              src={images[0].permalink}
              alt={images[0].alt}
              layout="fill"
              objectFit="cover"
              className="rounded-2xl"
            />
          )}
        </div>
        <div className={styles.secondImage}>
          {images?.length && images[1] && (
            <ImageComponent
              src={images[1].permalink}
              alt={images[1].alt}
              layout="fill"
              objectFit="cover"
              className="rounded-2xl"
            />
          )}
        </div>
        <div className={styles.thirdImage}>
          {images?.length && images[2] && (
            <ImageComponent
              src={images[2].permalink}
              alt={images[2].alt}
              layout="fill"
              objectFit="cover"
              className="rounded-2xl"
            />
          )}
        </div>
        <div
          className={styles.teaser}
          dangerouslySetInnerHTML={{ __html: teaser }}
        />
      </div>
    </section>
  )
}

export default ThreeImagesAndText

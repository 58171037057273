import styles from './WYSIWYG.module.scss'
import Section from '../../Section'
import cx from 'classnames'

const WYSIWYG = ({ content, isSection, className }) => {
  // console.log('WYSIWYG', content)
  return isSection ? (
    <Section className={cx(styles.sectionWrapper, className)} color={'white'}>
      <div
        dangerouslySetInnerHTML={{ __html: content?.html ?? content?.text }}
      />
    </Section>
  ) : (
    <div
      className={cx(styles.wysiwyg, className)}
      dangerouslySetInnerHTML={{ __html: content?.html ?? content?.text }}
    />
  )
}

export default WYSIWYG

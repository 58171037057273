import styles from './Cards.module.scss'
import cx from 'classnames'
import CustomLink from '../CustomLink'
import ImageComponent from '../../Image'
import cleanString from '../../../lib/cleanString'

const Card = ({ item, fullWidth }) => {
  const { icon, headline, teaser, link, linktext, linktype, newtab } = item
  return (
    <div
      className={cx({
        [styles.fullCard]: fullWidth,
        [styles.halfCard]: !fullWidth,
      })}
    >
      <div className={styles.cardImageWrapper}>
        <ImageComponent
          src={icon?.permalink}
          alt={icon?.alt}
          layout="fill"
          objectFit="cover"
          objectPosition={icon?.focus_css}
        />
      </div>
      <div className={styles.cardTextWrapper}>
        <div>
          {headline && (
            <h2
              className="text-24-35 md:text-40-60 font-medium"
              dangerouslySetInnerHTML={{ __html: cleanString(headline) }}
            />
          )}
          {teaser && (
            <div
              className="text-18-31 md:text-24-35"
              dangerouslySetInnerHTML={{ __html: teaser }}
            />
          )}
          {link && (
            <CustomLink
              link={link}
              linkType={linktype}
              newTab={newtab}
              className="mt-10"
            >
              {linktext}
            </CustomLink>
          )}
        </div>
      </div>
    </div>
  )
}

export const Cards = ({ content }) => {
  const { items } = content

  return (
    <section className={styles.sectionWrapper}>
      {items?.length &&
        items.map((item, i) => (
          <Card key={i} item={item} fullWidth={items.length === 2 || i === 0} />
        ))}
    </section>
  )
}

export default Cards

import styles from './Employee.module.scss'
import { useContext, useMemo } from 'react'
import { SiteInformationContext } from '../../../context/site-information-context'
import cx from 'classnames'
import ImageComponent from '../../Image'
import Section from '../../Section'

const Employee = ({ employee, size, order }) => {
  const { title, quote, job_title, portrait } = employee

  const wrapperCx = cx({
    [styles.wrapperLarge]: size === 'large',
    [styles.wrapperMedium]: size === 'medium',
    [styles.wrapperSmall]: size === 'small',
    'flex-row-reverse': order === 4,
    'flex-row-reverse lg:flex-row': order === 2,
  })

  return (
    <div className={wrapperCx}>
      <div className={styles.left}>
        <div className={styles.portraitWrapper}>
          <ImageComponent
            src={portrait.permalink}
            alt={portrait.alt}
            layout="fill"
            objectFit="cover"
            objectPosition={portrait.focus_css}
            className="rounded-2xl"
          />
        </div>
        <div
          className={cx({
            hidden: size === 'large',
            'hidden lg:block': size !== 'large',
          })}
        >
          {title && (
            <div
              className={cx('md:text-18-27 font-semibold', {})}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {job_title && (
            <div
              className={cx('text-18-27 text-gray', {})}
              dangerouslySetInnerHTML={{ __html: job_title }}
            />
          )}
        </div>
      </div>
      <div
        className={cx(styles.right, {
          'lg:px-10': size === 'medium' && order === 2,
          'lg:pr-[1.75rem]': size === 'medium' && order === 4,
        })}
      >
        {quote && (
          <div
            className={cx('text-20-30 md:text-40-61 mb-4 lg:mb-12')}
            dangerouslySetInnerHTML={{ __html: quote }}
          />
        )}
        <div
          className={cx({
            'block lg:hidden': size !== 'large',
            block: size === 'large',
          })}
        >
          {title && (
            <div
              className={cx('md:text-18-27 font-semibold', {})}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {job_title && (
            <div
              className={cx('md:text-18-27 text-gray', {})}
              dangerouslySetInnerHTML={{ __html: job_title }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const EmployeeBlock = ({ content }) => {
  const siteInformation = useContext(SiteInformationContext)
  const {
    variant: { value: variant },
    employeeblock_color: { value: color },
    employeeblock_gradient: gradient,
    employeeblock_gradient_color: { value: gradientColor },
    employeeblock_gradient_position: { value: gradientPosition },
    headline,
  } = content

  const employees = useMemo(
    () =>
      siteInformation?.employees?.data?.filter(
        item => item && content?.employees.find(id => id === item.id)
      ) || [],
    [content?.employees, siteInformation?.employees?.data]
  )

  return employees.length > 0 ? (
    variant === 'small' ? (
      <Section
        classNames={cx(styles.sectionWrapper, {
          'text-white': color === 'blue' || color === 'dark-blue',
          'text-purple': color === 'yellow' || color === 'white',
        })}
        color={color}
        gradient={gradient}
        gradientColor={gradientColor}
        gradientPosition={gradientPosition}
      >
        <div className={cx(styles.sectionWrapperInnerSmall)}>
          <div
            className={styles.headline}
            dangerouslySetInnerHTML={{ __html: headline }}
          />
          <div className={styles.employeesWrapper}>
            {employees.map((employee, i) => (
              <div className={styles.employee} key={i}>
                <div className={styles.employeeImage}>
                  <ImageComponent
                    src={employee.portrait.permalink}
                    alt={employee.portrait.alt}
                    layout="fill"
                    objectFit="cover"
                    objectPosition={employee.portrait.focus_css}
                    className="rounded-2xl"
                  />
                </div>
                <div>
                  {employee.title && (
                    <div
                      className={cx('md:text-18-27 font-semibold', {})}
                      dangerouslySetInnerHTML={{ __html: employee.title }}
                    />
                  )}
                  {employee.job_title && (
                    <div
                      className={cx('md:text-18-27 text-gray', {})}
                      dangerouslySetInnerHTML={{ __html: employee.job_title }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Section>
    ) : (
      <section
        className={cx(styles.sectionWrapper, {
          'bg-white': color === 'white',
          'bg-yellow': color === 'yellow',
          'bg-purple': color === 'blue',
          'text-white': color === 'blue',
          'text-purple': color === 'yellow' || color === 'white',
        })}
      >
        <div className={styles.sectionWrapperInner}>
          <div dangerouslySetInnerHTML={{ __html: headline }} />
          {employees.map((employee, i) => (
            <Employee
              key={employee.id}
              employee={employee}
              size={i === 0 ? 'large' : i === 1 || i === 3 ? 'medium' : 'small'}
              order={i + 1}
            />
          ))}
        </div>
      </section>
    )
  ) : null
}

export default EmployeeBlock

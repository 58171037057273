import styles from './CaseStudies.module.scss'
import cx from 'classnames'
import { Swiper } from 'swiper/react'
import { Keyboard, Navigation, Pagination } from 'swiper'
import CaseStudiesSlide from './CaseStudiesSlide'
import { useState } from 'react'

export const CaseStudies = ({ content, className }) => {
  const { items } = content

  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  function getSwiperActiveIndex(swipe) {
    var activeIndex = swipe.activeIndex
    var slidesLen = swipe.slides.length
    if (swipe.params.loop) {
      switch (swipe.activeIndex) {
        case 0:
          activeIndex = slidesLen - 3
          break
        case slidesLen - 1:
          activeIndex = 0
          break
        default:
          --activeIndex
      }
    }
    return activeIndex
  }

  return (
    <div className={cx(styles.sectionWrapper, className)}>
      <div className={styles.swiper}>
        <Swiper
          modules={[Pagination, Navigation, Keyboard]}
          keyboard={{
            enabled: true,
            onlyInViewport: true,
          }}
          speed={600}
          loopedSlides={items.length}
          loop={true}
          autoplay={false}
          navigation={true}
          pagination={{
            el: `.${styles.swiperPagination}`,
            clickable: true,
            renderBullet: function (index, className) {
              return `<span class="${cx(
                className,
                styles.swiperPaginationBullet,
                {
                  [styles.active]: activeSlideIndex === index,
                }
              )}"></span>`
            },
          }}
          spaceBetween={17}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1.5,
              centeredSlides: true,
            },
          }}
          onSlideChange={swipe => {
            setActiveSlideIndex(getSwiperActiveIndex(swipe))
          }}
        >
          {items &&
            items.map((item, index) => (
              <CaseStudiesSlide content={item} key={index} />
            ))}
        </Swiper>
      </div>
      <div className={styles.swiperPagination} />
    </div>
  )
}

export default CaseStudies

import styles from './Logos.module.scss'
import cx from 'classnames'
import ImageComponent from '../../Image'

const LogosBlock = ({ content, isSection }) => {
  const { logos, teaser } = content

  const Tag = isSection ? 'section' : 'div'
  return (
    <Tag
      className={cx(styles.wrapper, {
        [styles.sectionWrapper]: isSection,
        [styles.divWrapper]: !isSection,
      })}
    >
      {logos?.length &&
        logos.map((logo, i) => (
          <div key={i} className={styles.logo}>
            <ImageComponent
              src={logo.permalink}
              alt={logo.alt}
              layout="intrinsic"
              width={logo.width}
              height={logo.height}
              objectFit="contain"
            />
          </div>
        ))}
      {teaser && (
        <div
          className={styles.teaser}
          dangerouslySetInnerHTML={{ __html: teaser }}
        />
      )}
    </Tag>
  )
}

export default LogosBlock

import styles from './TerminalsTwoColumn.module.scss'
import { useContext, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import cleanString from '../../../lib/cleanString'
import CustomLink from '../CustomLink'
import Terminal from './Terminal'
import { SiteInformationContext } from '../../../context/site-information-context'
import ScrollTopButton from '../../ScrollTopButton'
const slugify = require('slugify')

const Index = ({ className, categories, activeCategory }) => (
  <div className={cx(styles.twoColumnJumpIndex, className)}>
    {categories?.map((category, i) => (
      <div key={`index-wrapper-${i}`}>
        <a
          key={i}
          href={`#${category?.jumpTarget}`}
          className={cx(styles.anchorLink, {
            [styles.active]:
              category?.jumpTarget === activeCategory?.jumpTarget,
          })}
        >
          {category.headline}
        </a>
        <div
          className={cx(styles.teaser, {
            [styles.noDisplay]:
              category?.jumpTarget !== activeCategory?.jumpTarget,
          })}
          dangerouslySetInnerHTML={{
            __html: cleanString(category.teaser),
          }}
        />
        {category?.jumpTarget === activeCategory?.jumpTarget &&
          category.link.link && (
            <CustomLink
              linkType={category.link.linkType}
              link={category.link.link}
              newTab={category.link.newTab}
              color={category.link.color.value}
              className={styles.link}
            >
              {category.link.linkText}
            </CustomLink>
          )}
      </div>
    ))}
  </div>
)

export const TerminalsTwoColumn = ({ content = null }) => {
  const [categories, setCategories] = useState([])
  const [activeCategory, setActiveCategory] = useState(null)

  const siteInformationContext = useContext(SiteInformationContext)
  const [siteInformation, setSiteInformation] = useState({})
  useEffect(() => {
    if (siteInformationContext && siteInformationContext) {
      setSiteInformation(siteInformationContext)
    }
  }, [siteInformationContext])

  useEffect(() => {
    setCategories(
      content?.blocks
        ?.filter(block => block.type === 'terminaltwocolumnblockset')
        ?.map(block => {
          const {
            color: color,
            link: link,
            linktext: linkText,
            linktype: linkType,
            newtab: newTab,
            headline: headline,
            teaser: teaser,
            terminals: terminalIds,
          } = block

          return {
            headline,
            teaser,
            link: {
              color,
              link,
              linkText,
              linkType,
              newTab,
            },
            terminals: siteInformation?.terminals?.data?.filter(terminal =>
              terminalIds?.includes(terminal.id)
            ),
            jumpTarget:
              slugify(headline, {
                replacement: '-',
                remove: '+',
                lower: true,
                strict: true,
                trim: true,
              }) || '#',
          }
        }) || []
    )
  }, [content, siteInformation?.terminals?.data])

  useEffect(() => {
    setActiveCategory(categories.length ? categories[0] : null)
    setJumpTargets(categories.map(category => category?.jumpTarget))
  }, [categories])

  const [jumpTargets, setJumpTargets] = useState([])
  const [lastVisibleJumpTarget, _setLastVisibleJumpTarget] = useState(null)
  const lastVisibleJumpTargetRef = useRef(lastVisibleJumpTarget)
  const setLastVisibleJumpTarget = data => {
    lastVisibleJumpTargetRef.current = data
    _setLastVisibleJumpTarget(data)
  }

  useEffect(() => {
    setActiveCategory(
      categories.find(
        category => category.jumpTarget === lastVisibleJumpTargetRef.current
      ) || (categories.length ? categories[0] : null)
    )
  }, [categories, lastVisibleJumpTarget])

  const debouncedCheck = () => {
    const method = checkActiveTarget
    clearTimeout(method._tId)
    method._tId = setTimeout(function () {
      method()
    }, 1)
  }

  const checkActiveTarget = () => {
    const visibleJumpTargets =
      jumpTargets?.filter(jumpTarget => {
        const el = document.querySelector(`#${jumpTarget}`)
        return (
          el &&
          el.getBoundingClientRect().top > 0 &&
          el.getBoundingClientRect().top < window.innerHeight
        )
      }) || []

    if (visibleJumpTargets.length > 0) {
      const t = visibleJumpTargets[visibleJumpTargets.length - 1]
      if (t && t !== lastVisibleJumpTargetRef.current) {
        setLastVisibleJumpTarget(t)
      }
    }
  }

  if (typeof window !== 'undefined') {
    window.removeEventListener('scroll', debouncedCheck)
    window.addEventListener('scroll', debouncedCheck, {
      passive: true,
    })
  }

  return (
    <div className={styles.twoColumnJump}>
      <ScrollTopButton />

      <Index
        categories={categories}
        activeCategory={activeCategory}
        className={cx('hidden lg:block')}
      />

      <div className={styles.twoColumnJumpContent}>
        {categories &&
          categories?.map((category, i) => (
            <div
              className={styles.anchorTarget}
              id={category.jumpTarget}
              key={category.jumpTarget}
            >
              <Index
                key={i}
                categories={categories}
                activeCategory={activeCategory}
                className={'block lg:hidden'}
              />

              {category.terminals?.map(terminal => (
                <Terminal
                  content={terminal}
                  key={terminal.id}
                  className={'mb-0'}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  )
}

export default TerminalsTwoColumn

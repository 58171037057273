import useSWR from 'swr'

export const useSocialFeed = options => {
  const { data, error } = useSWR(`/api/socialfeed`, options)

  return {
    socialfeed: data,
    isLoading: !error && !data,
    isError: error,
  }
}
